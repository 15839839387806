import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchMyProfile, submitLogout } from "./authAPI";
import { AxiosError } from "axios";
import { patchMyProfile } from "../users/usersAPI";
import config from "../../config";

const initialState = {
  user: null as null | {
    id: string;
    email: string;
    role: string;
  },
  isAuthPending: true as boolean,
};

export const ERROR_CODE_UNAUTHORIZED = "UNAUTHORIZED";
export const ERROR_CODE_BAD_CREDENTIALS = "BAD_CREDENTIALS";

export class AuthError extends Error {
  code: string;
  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
}

export const loadAllProfileData = createAsyncThunk(
  "auth/loadAllProfileData",
  async (_: any, { dispatch }) => {
    try {
      const user = await fetchMyProfile();
      return {
        user,
      };
    } catch (err) {
      if (err instanceof AxiosError && !config.production) {
        switch (err.response?.status) {
          case 401:
            if (!config.production) {
              dispatch(logout());
              throw err;
            }
            window.location.href =
              "https://app.incquery.com/accounts/login?next=%2Fquest";
        }
      }
      throw err;
    }
  }
);

export const getMyUserProfile = createAsyncThunk(
  "auth/getMyUserProfile",
  async (_: any, { dispatch }) => {
    try {
      return await fetchMyProfile();
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 401:
            if (!config.production) {
              dispatch(logout());
              return;
            }
            window.location.href =
              "https://app.incquery.com/login?next=https%3A%2F%2Fapp.incquery.com%2Fquest";
        }
      }
      throw err;
    }
  }
);

export const updateMyProfile = createAsyncThunk(
  "users/updateMyProfile",
  async ({ name }: { name: string }, { dispatch }) => {
    const user = await patchMyProfile({ name });
    return user;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await submitLogout();
  return response;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getMyUserProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.user = action.payload.user;
        }
      )
      .addCase(getMyUserProfile.rejected, (state: any, action: any) => {
        if (action.error?.code === ERROR_CODE_UNAUTHORIZED) {
          state.user = null;
        }
      })
      .addCase(logout.fulfilled, (state: any, action: PayloadAction<void>) => {
        state.user = null;
        state.isAuthPending = false;
      })
      .addCase(
        loadAllProfileData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.user = action.payload.user;
          state.isAuthPending = false;
        }
      )
      .addCase(
        loadAllProfileData.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.isAuthPending = false;
        }
      )
      .addCase(
        updateMyProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.user = action.payload;
        }
      );
  },
});

export default authSlice.reducer;
