import { TestReportData, TraversalData, EdgeData } from "./types";
import exampleSurvey from "./exampleSurvey.json";

export const exampleReport = getExampleSurvey();

function getExampleSurvey(): TestReportData {
  const survey = exampleSurvey;
  const nodes = survey.sections.flatMap((section) =>
    section.questions.map((question, questionIndex) => {
      return {
        id: question.id,
        text: question.text,
        nickname: question.nickname || "",
        visits: 0,
        options:
          question.type === "multiple_choice" && question.choiceItems
            ? question.choiceItems.map((item) => ({
                id: item,
                text: item,
                visits: 0,
              }))
            : [],
      };
    })
  );

  const insideSectionEdges = survey.sections.flatMap((section) => {
    return section.questions
      .map((question, index) => {
        if (index < section.questions.length - 1) {
          return {
            sourceId: question.id,
            targetId: section.questions[index + 1].id,
            visits: 0,
          };
        }
        return null;
      })
      .filter((edge) => edge !== null);
  });

  const lastQuestionEdges = survey.sectionEdges.map((edge) => {
    const sourceSection = survey.sections.find(
      (section) => section.id === edge.source
    );
    if (!sourceSection) throw new Error("Section not found");
    const nextSection = survey.sections.find(
      (section) => section.id === edge.target
    );
    if (!nextSection) throw new Error("Next section not found");
    return {
      sourceId: sourceSection.questions[sourceSection.questions.length - 1].id,
      targetId: nextSection.questions[0].id,
      visits: 0,
    };
  });
  return {
    url: "https://example.com",
    nodes,
    edges: [...lastQuestionEdges, ...insideSectionEdges],
    traversalCount: 0,
  };
}

export function traverse(survey: TestReportData): TraversalData {
  const traversal: TraversalData = {
    nodesVisited: [],
    optionsVisited: [],
    edgesTraversed: [],
  };
  let currentNodeId = survey.nodes[0].id;
  while (currentNodeId) {
    const currentNode = survey.nodes.find((node) => node.id === currentNodeId);
    if (!currentNode) throw new Error("Current node not found");
    traversal.nodesVisited.push(currentNode);
    if (currentNode.options.length > 0) {
      const option = selectRandom(currentNode.options);
      traversal.optionsVisited.push({
        nodeId: currentNodeId,
        optionId: option.id,
      });
    }

    const validEdges = survey.edges.filter(
      (edge) => edge.sourceId === currentNodeId
    );
    if (validEdges.length === 0) break;
    const edge = selectRandom(validEdges);
    traversal.edgesTraversed.push(edge);
    const nextNodeId = edge.targetId;
    if (!nextNodeId) throw new Error("Next node ID is empty");
    currentNodeId = nextNodeId;
  }
  return traversal;
}

export function updateTestReportWithTraversal(
  survey: TestReportData,
  traversal: TraversalData
): TestReportData {
  const updatedSurvey = {
    ...survey,
    traversalCount: survey.traversalCount + 1,
  };
  traversal.nodesVisited.forEach((node) => {
    const currentNode = updatedSurvey.nodes.find((n) => n.id === node.id);
    if (!currentNode) {
      updatedSurvey.nodes.push(node);
    } else {
      currentNode.visits = currentNode.visits + 1;
    }
  });
  traversal.optionsVisited.forEach((optionLocation) => {
    const node = updatedSurvey.nodes.find(
      (n) => n.id === optionLocation.nodeId
    );
    if (!node) throw new Error("Node not found");
    const option = node.options.find((o) => o.id === optionLocation.optionId);
    if (!option) throw new Error("Option not found");
    option.visits = option.visits + 1;
  });
  traversal.edgesTraversed.forEach((edge) => {
    const currentEdge = updatedSurvey.edges.find(
      (e) => e.sourceId === edge.sourceId && e.targetId === edge.targetId
    );
    if (!currentEdge) {
      updatedSurvey.edges.push(edge);
    } else {
      currentEdge.visits = currentEdge.visits + 1;
    }
  });
  return updatedSurvey;
}

function selectRandom(array: any[]) {
  return array[Math.floor(Math.random() * array.length)];
}
