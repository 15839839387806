import { useEffect, useRef } from "react";
import mermaid from "mermaid";

export default function ReportDiagram({ diagram }: { diagram: string }) {
  const preRef = useRef<HTMLPreElement>(null);

  useEffect(() => {
    mermaid.contentLoaded();
  }, [diagram]);

  useEffect(() => {
    // Remove data-processed attribute after mermaid processes the diagram
    if (preRef.current) {
      preRef.current.removeAttribute("data-processed");
    }
  }, [diagram]);

  return (
    <pre
      ref={preRef}
      className="mermaid whitespace-pre-wrap flex flex-row justify-center"
    >
      {diagram}
    </pre>
  );
}
