export default function TestURLForm({
  onSubmit,
}: {
  onSubmit: (url: string) => void;
}) {
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const surveyUrl = formData.get("survey-url");
    onSubmit(surveyUrl as string);
  }
  return (
    <form className="flex flex-row mt-12" onSubmit={handleSubmit}>
      <input
        id="survey-url"
        name="survey-url"
        type="survey-url"
        aria-label="Survey Test URL"
        placeholder="Enter a survey test URL"
        aria-describedby="survey-url-description"
        className="block w-full rounded-md bg-white px-4 py-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-700 sm:text-sm/6"
      />
      <button
        type="submit"
        className="ml-2 inline-flex items-center rounded bg-sky-900 px-4 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800 transition-colors duration-200"
      >
        Analyze
      </button>
    </form>
  );
}
