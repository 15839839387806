import { TestReportData } from "./types";
import ReportDiagram from "./ReportDiagram";
import { toDiagram } from "./mermaid";
import ReportQuestionTable from "./ReportQuestionTable";
export default function TestReport({
  testReport,
}: {
  testReport: TestReportData;
}) {
  return (
    <div>
      <p>URL: {testReport.url}</p>
      <p>Questions found: {testReport.nodes.length}</p>
      <p>Submissions: {testReport.traversalCount}</p>

      <div className="mt-4">
        <ReportDiagram diagram={toDiagram(testReport)} />
        <ReportQuestionTable testReport={testReport} />
      </div>
    </div>
  );
}
