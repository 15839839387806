import { TestReportData } from "./types";

export default function ReportQuestionTable({
  testReport,
}: {
  testReport: TestReportData;
}) {
  return (
    <table className="table-auto">
      <thead>
        <tr>
          <th className="text-left pr-12">Question</th>
          <th className="text-left pr-12"></th>
          <th className="text-left pr-12">Answers</th>
          <th className="text-left pr-12">Destinations</th>
        </tr>
      </thead>
      <tbody>
        {testReport.nodes.map((node) => (
          <tr key={node.id}>
            <td className="pr-12 pb-6">
              {node.nickname} ({node.visits})
            </td>
            <td className="pr-12 pb-6">{node.text}</td>
            <td className="pr-12 pb-6">
              {node.options.map((option) => (
                <div key={option.id}>
                  {option.text} ({option.visits})
                </div>
              ))}
            </td>
            <td className="pr-12 pb-6">
              {testReport.edges
                .filter((edge) => edge.sourceId === node.id)
                .map((edge) => {
                  const targetNode = testReport.nodes.find(
                    (node) => node.id === edge.targetId
                  );
                  if (!targetNode) return "Unknown";
                  return `${
                    targetNode.nickname || targetNode.text.slice(0, 10)
                  } (${edge.visits})`;
                })
                .join(", ")}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
