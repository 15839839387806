import EditableDiv from "../EditableDiv";
import styles from "./Wizard.module.css";
import { toHTML } from "../../../../../../../shared/utils/html";
import { SurveyDraftData } from "../../../survey.types";
import SubmitButton from "../../../../../components/form/SubmitButton";
import { useEffect, useState } from "react";
import { handleError } from "../../../../errors/errorUtils";
import { exampleDescriptions } from "../../../../../../../shared/utils/surveyExamples";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../../../../components/Tooltip";

export default function WizardDescriptionForm({
  onSubmit,
  survey,
  setObjectives,
  setRespondentType,
  setAudience,
  hideNext,
}: {
  survey: SurveyDraftData;
  setObjectives: (objectives: string) => void;
  setRespondentType: (respondentType: string) => void;
  setAudience: (audience: string) => void;
  onSubmit: (generate: boolean) => Promise<void>;
  hideNext?: boolean;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [exampleCounter, setExampleCounter] = useState(0);

  const [checkTooShort, setCheckTooShort] = useState(false);
  const OBJECTIVES_MAX_LENGTH = 1000;
  const RESPONDENT_PROFILE_MAX_LENGTH = 500;
  const warnObjectivesTooShort = survey.objectives.length < 50 && checkTooShort;
  const warnRespondentProfileTooShort =
    survey.audience.length < 50 && checkTooShort;
  const warnObjectivesTooLong =
    survey.objectives.length > OBJECTIVES_MAX_LENGTH - 100;
  const warnRespondentProfileTooLong =
    survey.audience.length > RESPONDENT_PROFILE_MAX_LENGTH - 100;

  const warnObjectives = warnObjectivesTooShort || warnObjectivesTooLong;
  const warnRespondentProfile =
    warnRespondentProfileTooShort || warnRespondentProfileTooLong;

  async function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleSubmit(true);
  }
  async function handleSubmit(generate: boolean = true) {
    if (survey.objectives.length < 50 || survey.audience.length < 50) {
      setCheckTooShort(true);
      return;
    }
    if (survey.plan.length > 0) {
      const confirm = window.confirm(
        "Are you sure you want to regenerate your outline? This will overwrite any changes you've made."
      );
      if (!confirm) return;
    }
    try {
      if (submitting || !survey.objectives) return;
      setSubmitting(true);

      await onSubmit(generate);
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }
  function loadExample() {
    if (survey.objectives) {
      const confirm = window.confirm(
        "Are you sure you want to load an example? This will overwrite your current objectives."
      );
      if (!confirm) return;
    }
    const { respondentType, description, audience } =
      exampleDescriptions[exampleCounter % exampleDescriptions.length];
    setExampleCounter((counter) => counter + 1);
    setObjectives(description);
    setAudience(audience);
    setRespondentType(respondentType);
  }

  return (
    <div className="pb-4 flex-1 pr-6">
      <form onSubmit={handleFormSubmit}>
        <div className="">
          <div>
            <div className="w-full">
              <label
                className="block leading-6 text-sm text-gray-500"
                htmlFor="objectives"
              >
                Describe your survey objectives and respondent profile.{" "}
                <span
                  className={`${warnObjectivesTooLong ? "text-red-600" : ""}`}
                >
                  If you have a survey outline, you can add it in the next step.
                </span>
              </label>
              <div className="mb-8 mt-4">
                <div className="flex flex-row items-end">
                  <label className="font-medium text-sm mb-1 block flex-1">
                    Objectives
                  </label>
                  <div className="mb-1">
                    <button
                      type="button"
                      className="underline text-xs text-gray-400 py-0"
                      onClick={loadExample}
                    >
                      Need an example?
                    </button>
                  </div>
                </div>

                <textarea
                  id="objectives"
                  aria-label="Description"
                  value={survey.objectives}
                  rows={8}
                  onChange={(e) => setObjectives(e.target.value)}
                  autoFocus={survey.objectives.length === 0}
                  className={`${
                    styles["objectives-input"]
                  } block w-full rounded-md border-0 -mx-1 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 ${
                    warnObjectives ? "ring-red-600" : ""
                  }`}
                  placeholder="e.g. Understand the project management software market. Identify key purchasing criteria, NPS, likelihood to switch."
                  maxLength={OBJECTIVES_MAX_LENGTH}
                />
                <div
                  className={`pt-1 pr-2 float-right text-xs flex-1 flex flex-row ${
                    warnObjectives ? "text-red-600" : "text-gray-400"
                  }`}
                >
                  <div className="mr-2">
                    {warnObjectivesTooShort
                      ? "Please enter at least 50 characters."
                      : ""}
                  </div>
                  <div>
                    {survey.objectives.length} / {OBJECTIVES_MAX_LENGTH}
                  </div>
                </div>
              </div>
              <div>
                <label className="font-medium text-sm block mb-1">
                  Respondent Profile
                </label>
                <textarea
                  id="respondent-profile"
                  aria-label="Respondent Profile"
                  value={survey.audience}
                  rows={3}
                  onChange={(e) => setAudience(e.target.value)}
                  autoFocus={survey.audience.length === 0}
                  className={`${
                    styles["objectives-input"]
                  } block w-full rounded-md border-0 -mx-1 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 ${
                    warnRespondentProfile ? "ring-red-600" : ""
                  }`}
                  placeholder="e.g. Software decision-makers at organizations more than $1M in revenue."
                  maxLength={RESPONDENT_PROFILE_MAX_LENGTH}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row pt-3">
                <div className="w-44">
                  <label
                    htmlFor="respondentType"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Respondent type
                    <Tooltip>
                      <TooltipTrigger>
                        <QuestionMarkCircleIcon className="ml-2 w-4 h-4 inline-block" />
                      </TooltipTrigger>
                      <TooltipContent className="bg-gray-800 text-white text-xs px-4 py-2 rounded">
                        Internal surveys are not yet supported.
                      </TooltipContent>
                    </Tooltip>
                  </label>
                  <select
                    id="respondentType"
                    name="respondentType"
                    value={survey.respondentType || ""}
                    onChange={(e) => setRespondentType(e.target.value)}
                    className="mt-2 -mx-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">--- Select one ---</option>
                    <option value="b2b">B2B</option>
                    <option value="b2c">B2C</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div
                  className={`pt-1 pr-2 float-right text-xs flex-1 flex flex-row ${
                    warnRespondentProfile ? "text-red-600" : "text-gray-400"
                  }`}
                >
                  <div className="mr-2">
                    {warnRespondentProfileTooShort
                      ? "Please enter at least 50 characters."
                      : ""}
                  </div>
                  <div>
                    {survey.audience.length} / {RESPONDENT_PROFILE_MAX_LENGTH}
                  </div>
                </div>
                {hideNext ? null : (
                  <div className="flex flex-1 flex-row justify-end items-end">
                    <button
                      type="button"
                      className="underline text-sm text-gray-500 text-left mr-4 disabled:text-gray-300"
                      disabled={
                        !survey.objectives ||
                        !survey.respondentType ||
                        !survey.audience ||
                        submitting
                      }
                      onClick={() => handleSubmit(false)}
                    >
                      I already have an outline
                    </button>
                    <SubmitButton
                      disabled={
                        !survey.objectives ||
                        !survey.respondentType ||
                        !survey.audience
                      }
                      submitting={submitting}
                      className="w-40 items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
                    >
                      Generate Outline
                    </SubmitButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
