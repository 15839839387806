import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../../components/form/SubmitButton";
import { handleError } from "../errors/errorUtils";
import { useState } from "react";
import { formatDistance, parseISO } from "date-fns";
import { TrashIcon } from "@heroicons/react/24/outline";
import styles from "./SurveyList.module.css";
import { useAppDispatch } from "../../app/hooks";
import { archiveSurvey } from "./surveySlice";
import { throwIfError } from "../../utils/error";
import { SparklesIcon } from "@heroicons/react/20/solid";
import CreateDraftDialog from "./CreateDraftDialog";

function SurveyListItem({ survey }: { survey: any }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  async function handleArchiveClick() {
    try {
      const archive = confirm("Are you sure you want to archive this survey?");
      if (archive) {
        await throwIfError(dispatch(archiveSurvey(survey.id)));
      }
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <div
      className={`${styles["survey-list-item"]} flex flex-row`}
      data-item-id={survey.id}
    >
      <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 flex-1">
        <Link to={`/drafter/surveys/${survey.id}`}>
          {survey.title.trim() || "Untitled"}
        </Link>
      </div>
      <div
        className="whitespace-nowrap py-4 pl-4 pr-3 text-xs text-gray-500 sm:pl-0"
        style={{ width: 200 }}
      >
        {formatDistance(parseISO(survey.updatedAt), Date.now(), {
          addSuffix: true,
        })}
      </div>
      <div
        className={`${styles["archive-button"]} whitespace-nowrap pl-4 pr-3 text-xs text-gray-500 sm:pl-0 flex flex-col justify-center text-left`}
        style={{ width: 80 }}
      >
        <button
          className="inline-block rounded bg-white text-sm focus:outline-none focus:ring-2 focus:ring-sky-400 focus:ring-offset-2 hover:bg-gray-100 p-2 w-8 h-8"
          onClick={handleArchiveClick}
        >
          <span className="sr-only">Archive</span>
          <TrashIcon />
        </button>
      </div>
    </div>
  );
}

export default function SurveyList({
  surveys,
  onCreateClick,
  emptyMessage,
}: {
  surveys: any[];
  onCreateClick?: () => Promise<void> | void;
  emptyMessage?: string;
}) {
  const [creating, setCreating] = useState(false);
  async function handleCreateClick() {
    if (!onCreateClick) return;
    try {
      setCreating(true);
      await onCreateClick();
    } catch (err) {
      handleError(err);
    } finally {
      setCreating(false);
    }
  }

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Survey Drafts
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <SubmitButton
            submitting={creating}
            onClick={handleCreateClick}
            aria-label="Create Survey"
          >
            <SparklesIcon className="w-4 h-4 mr-2 relative top-0.5" />
            Create (beta)
          </SubmitButton>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="min-w-full divide-y divide-gray-300">
              <div className="flex flex-row">
                <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 flex-1">
                  Name
                </div>
                <div
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  style={{ width: 200 }}
                >
                  Last Edited
                </div>
                <div style={{ width: 80 }}></div>
              </div>
              <div className="divide-y divide-gray-200">
                {surveys.map((survey) => (
                  <SurveyListItem key={survey.id} survey={survey} />
                ))}
              </div>
            </div>
          </div>
        </div>
        {surveys.length === 0 ? (
          <div className="text-center text-gray-500 text-sm py-12">
            {typeof emptyMessage === "string"
              ? emptyMessage
              : "No surveys found."}
          </div>
        ) : null}
      </div>
    </div>
  );
}
