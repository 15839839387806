import { useState } from "react";
import TestURLForm from "../features/testing/TestURLForm";
import TestReport from "../features/testing/TestReport";
import { TestReportData } from "../features/testing/types";
import { updateTestReportWithTraversal } from "../features/testing/visit";
import { exampleReport } from "../features/testing/visit";
import { traverse } from "../features/testing/visit";

export default function TestView() {
  const [report, setReport] = useState<TestReportData | null>(null);

  function handleSubmit(url: string) {
    setReport({
      url,
      nodes: [],
      edges: [],
      traversalCount: 0,
    });
    let rounds = 0;
    const interval = setInterval(() => {
      setReport((report) => {
        if (!report) return null;
        const traversal = traverse(exampleReport);
        console.log(traversal);
        return updateTestReportWithTraversal(report, traversal);
      });
      rounds++;
      if (rounds >= 30) {
        clearInterval(interval);
      }
    }, 1000);
  }

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="max-w-3xl w-full">
        <TestURLForm onSubmit={handleSubmit} />
        {report ? (
          <div className="mt-8">
            <TestReport testReport={report} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
