import { Dispatch, SetStateAction, useState } from "react";
import { SurveyData } from "../../../survey.types";
import PlanEditor from "../../PlanEditor";
import { CenteredSpinner } from "../../../../../components/Spinner";

export default function WizardPlanForm({
  survey,
  setSurvey,
  onSubmit,
  generating,
  disableNext,
}: {
  survey: SurveyData;
  generating: boolean;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
  onSubmit: () => void;
  disableNext?: boolean;
}) {
  const [warning, setWarning] = useState("");

  function handleSubmit() {
    const lines = survey.plan
      .split(/\n|<p>|<br>/)
      .filter((line) => line.trim().length > 0); // Filter out empty lines after splitting

    if (survey.plan.length === 0) {
      setWarning("Please enter a plan.");
      return;
    } else if (lines.length < 10) {
      setWarning(
        "Please enter a plan with at least 10 questions. Please ensure each question is a new line. If you need help, we recommend using AI to generate your initial outline."
      );
      return;
    }
    onSubmit();
  }
  const stepCount = survey.sections
    .map((s) => s.steps.length)
    .reduce((a, b) => a + b, 0);
  return (
    <div className="pb-4 flex-1 pr-6">
      <div className="text-sm text-gray-600 mb-4 italic">
        Enter a detailed outline for your survey.
      </div>
      <div className="">
        <div className="w-full mb-4">
          {generating && stepCount <= 1 ? (
            <div className="py-12">
              <CenteredSpinner />
            </div>
          ) : (
            <PlanEditor setSurvey={setSurvey} survey={survey} />
          )}
          {warning && !generating ? (
            <div className="text-red-500 text-sm mt-2">{warning}</div>
          ) : null}
        </div>

        <div>
          <div className="flex flex-row justify-end">
            <button
              onClick={handleSubmit}
              disabled={survey.plan.length === 0 || disableNext}
              className="inline-flex items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
