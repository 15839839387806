import { saveFileFromBlob } from "../../../../utils/download";
import axios from "axios";
import DropdownOptions from "./DropdownOptions";

export default function ExportButton({ surveyId }: { surveyId: string }) {
  async function downloadFile(
    endpoint: "download-docx" | "download-text" | "download-json"
  ) {
    {
      const { data, headers } = await axios.post(
        `/api/surveys/${surveyId}/${endpoint}`,
        {},
        {
          responseType: "blob",
          timeout: endpoint === "download-json" ? 120000 : undefined,
        }
      );
      saveFileFromBlob(
        data,
        headers["content-disposition"].replaceAll("attachment; filename=", "")
      );
    }
  }

  return (
    <DropdownOptions
      options={[
        {
          name: ".docx",
          onClick: () => downloadFile("download-docx"),
        },
        {
          name: ".json",
          onClick: () => downloadFile("download-json"),
        },
        {
          name: ".txt",
          onClick: () => downloadFile("download-text"),
        },
      ]}
    />
  );
}
