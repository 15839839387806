import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import HomeView from "../views/HomeView";
import AuthPage from "../components/pages/AuthPage";

import LogoutView from "../views/LogoutView";
import * as Sentry from "@sentry/react";
import ErrorFallback from "../components/ErrorFallback";
import LoginView from "../views/LoginView";
import NotFound from "../components/NotFound";
import LoggedOutPage from "../components/pages/LoggedOutPage";
import SettingsView from "../views/SettingsView";
import SurveyListView from "../views/SurveyListView";
import SurveyWorkspaceView from "../views/SurveyWorkspaceView";
import config from "../config";
import TestView from "../views/TestView";
const devRoutes = config.production
  ? []
  : [
      {
        path: "login",
        element: (
          <LoggedOutPage title="Login | Quest">
            <LoginView />
          </LoggedOutPage>
        ),
      },
      {
        path: "logout",
        element: <LogoutView />,
      },
      {
        path: "settings",
        element: (
          <AuthPage>
            <SettingsView />
          </AuthPage>
        ),
      },
    ];

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
        <App />
      </Sentry.ErrorBoundary>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: (
          <AuthPage>
            <HomeView />
          </AuthPage>
        ),
      },
    ],
  },
  {
    path: "/drafter",
    element: (
      <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
        <App />
      </Sentry.ErrorBoundary>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: (
          <AuthPage>
            <HomeView />
          </AuthPage>
        ),
      },
      ...devRoutes,
      {
        path: "surveys/:surveyId",
        element: (
          <AuthPage showNav={false} constrainToViewportHeight={true}>
            <SurveyWorkspaceView />
          </AuthPage>
        ),
      },
      {
        path: "surveys",
        element: (
          <AuthPage title="Drafts | IncQuery" flexCol={false}>
            <SurveyListView />
          </AuthPage>
        ),
      },
      {
        path: "test",
        element: (
          <AuthPage title="Test | IncQuery" flexCol={true}>
            <TestView />
          </AuthPage>
        ),
      },
      {
        path: "404",
        element: <NotFound />,
      },
    ],
  },
]);
