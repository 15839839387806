"use client";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  LightBulbIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { create } from "./surveyAPI";
import { useNavigate } from "react-router-dom";
import { handleError } from "../errors/errorUtils";
import { SparklesIcon } from "@heroicons/react/20/solid";

export default function CreateDraftDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  async function handleCreateClick(startFromOutline?: boolean) {
    try {
      const survey = await create({});
      navigate(
        `/drafter/surveys/${survey.id}${
          startFromOutline ? "?outline=true" : ""
        }`
      );
    } catch (err) {
      handleError(err);
    }
  }
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-sky-100">
                <SparklesIcon
                  aria-hidden="true"
                  className="size-6 text-yellow-400"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold text-gray-900"
                >
                  Where would you like to start?
                </DialogTitle>
                <div className="mt-2 divide-y">
                  <button
                    className="flex text-left w-full flex-row items-center h-20 hover:bg-gray-100 rounded px-6"
                    onClick={() => handleCreateClick()}
                    aria-label="Start from research objective"
                  >
                    <LightBulbIcon className="w-6 h-6 mr-6" />
                    <div className="pr-2">
                      <div className="font-medium text-sm">
                        From research objective
                      </div>
                      <div className="text-gray-500 text-xs">
                        We'll help you create an outline and generate survey
                        questions
                      </div>
                    </div>
                    <ArrowRightIcon className="w-5 h-5 ml-auto text-gray-500" />
                  </button>
                  <button
                    className="flex text-left w-full flex-row items-center h-20 hover:bg-gray-100 rounded px-6"
                    onClick={() => handleCreateClick(true)}
                    aria-label="Start from outline"
                  >
                    <ListBulletIcon className="w-6 h-6 mr-6" />
                    <div className="pr-2">
                      <div className="font-medium text-sm">From outline</div>
                      <div className="text-gray-500 text-xs">
                        Start with an existing outline to generate survey
                        questions
                      </div>
                    </div>
                    <ArrowRightIcon className="w-5 h-5 ml-auto text-gray-500" />
                  </button>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
