import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import PaddedContainer from "../components/containers/PaddedContainer";
import {
  getAllSurveys,
  selectAllSurveys,
} from "../features/survey/surveySlice";
import { throwIfError } from "../utils/error";
import { handleError } from "../features/errors/errorUtils";
import { useNavigate } from "react-router-dom";
import SurveyList from "../features/survey/SurveyList";
import { isBefore } from "date-fns";
import { create } from "../features/survey/surveyAPI";
import CreateDraftDialog from "../features/survey/CreateDraftDialog";

export default function SurveyListView() {
  const surveys = useAppSelector(selectAllSurveys);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const orderedSurveys = surveys.sort((a: any, b: any) =>
    isBefore(a.updatedAt, b.updatedAt) ? 1 : -1
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    try {
      throwIfError(dispatch(getAllSurveys()));
    } catch (err) {
      handleError(err);
    }
  }, []);

  return (
    <div className="pt-12">
      <PaddedContainer>
        <SurveyList
          surveys={orderedSurveys}
          onCreateClick={() => setShowCreateDialog(true)}
        />
      </PaddedContainer>
      <CreateDraftDialog
        open={showCreateDialog}
        setOpen={setShowCreateDialog}
      />
    </div>
  );
}
