import { TestReportData } from "./types";

export function toDiagram(testReport: TestReportData): string {
  const nodes = testReport.nodes
    .map((node) => {
      const label = (node.nickname || node.text.slice(0, 20))
        .replace(/"/g, "'")
        .replace(/[[\]]/g, "")
        .trim();
      return `${node.id}["${label}"]`;
    })
    .join("\n    ");

  const edges = testReport.edges
    .map((edge) => {
      return `${edge.sourceId} --> ${edge.targetId}`;
    })
    .join("\n    ");

  return `flowchart TD
    ${nodes}
    ${edges}`.trim();
}
